import React from 'react';
import { Link } from 'react-router-dom';

/**
* @author
* @function CartTotal
**/

const CartTotal = ({value}) => {
    const { cartSubTotal, cartTax, cartTotal, clearCart } = value;

  return(
    <React.Fragment>
        <div className="container">
            <div className="row">
                <div className="col-10 col-sm-8 mt-2 ml-sm-5 ml-md-auto text-capitalize text-right">
                    <button 
                        className="btn btn-outline-danger text-uppercase mb-3 px-5" type="button"
                        onClick={() => clearCart() }
                        >
                        clear cart
                    </button>
                    <h5>
                        <span className="text-title">subtotal: </span>
                        <strong>$ {cartSubTotal}</strong>
                    </h5>
                    <h5>
                        <span className="text-title">cart tax: </span>
                        <strong>{cartTax}</strong>
                    </h5>
                    <h5>
                        <span className="text-title">total: </span>
                        <strong>{cartTotal}</strong>
                    </h5>
                    
                    <Link to="/confirm">
                        <button className="btn btn-outline-primary text-uppercase mb-3 px-5" type="button">
                            <strong>Bill</strong>
                        </button>
                    </Link>
                </div>
            </div>

        </div>
    </React.Fragment>
   )

 }

export default CartTotal