import React from 'react';

/**
* @author
* @function Confirm
**/

const Confirm = (props) => {
  return(
    <div>Confirm</div>
   )

 }

export default Confirm