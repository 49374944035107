import React, { Component } from 'react';

/**
* @author
* @class Default
**/

class Default extends Component {
 render() {
  return(
   <div>Page not found</div>
    )
   }
}

export default Default;